<template>
      
    <div class="col s12 grey darken-2 center-align marginTop">

        <form class = 'marginTop' @submit.prevent="mnemonicCheck">
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word1" placeholder = "">
            <label for = "mnemonic1">Word #1</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word2" placeholder = "">
            <label for = "mnemonic2">Word #2</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word3" placeholder = "">
            <label for = "mnemonic3">Word #3</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word4" placeholder = "">
            <label for = "mnemonic4">Word #4</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word5" placeholder = "">
            <label for = "mnemonic5">Word #5</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word6" placeholder = "">
            <label for = "mnemonic6">Word #6</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word7" placeholder = "">
            <label for = "mnemonic7">Word #7</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word8" placeholder = "">
            <label for = "mnemonic8">Word #8</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word9" placeholder = "">
            <label for = "mnemonic9">Word #9</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word10" placeholder = "">
            <label for = "mnemonic10">Word #10</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word11" placeholder = "">
            <label for = "mnemonic11">Word #11</label>
            </div>
            <div class="input-field col s12 m4 l3">
            <input type = "text" class = "mnemonic" v-model="mnemonics.word12" placeholder = "">
            <label for = "mnemonic12">Word #12</label>
            </div>
            
            <!--Button-->
            <div class="col s12 center-align">
            <!--LOADER-->
                <Loader v-if="isLoading" />
                <!--Error-->
                <Error v-if="!isLoading && error!=null" :warning="error" />
                <button class = "btn white-text orange marginTop marginBottom" id = "walletRecoverBTN"><i class="fas fa-check"></i></button>
            </div>

        </form>
    
    </div>
        
</template>

<script>

import Loader from '@/components/UI/Loader';
import Error from '@/components/UI/Error';
import { reactive } from '@vue/reactivity';
import { post } from '@/composables/httpCalls'
import { encryptKey } from '@/composables/encryption'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { Loader, Error },
  
  setup(){

    const { router, isLoading, error } = useBoilerplate(false);
    
    const mnemonics = reactive({
      word1: '',
      word2: '',
      word3: '',
      word4: '',
      word5: '',
      word6: '',
      word7: '',
      word8: '',
      word9: '',
      word10: '',
      word11: '',
      word12: '',
    })

    const manageEncryptedKeys = async (xPriv, xPub) => {

      isLoading.value = true;
      
      const url = "/api/saveXpub";
      const vars = { xPub: xPub };

      try {

        const res = await post(url,vars);

        if(res.status == 200){
          
          const myResponse = await res.json();
          const encryptedKey = encryptKey(xPriv, myResponse.encKey);

          if(encryptKey == false){
            isLoading.value = false;
            return error.value = "La Semilla No Es Correcta"  
          }
          
          //Manage xPriv Locally
          localStorage.setItem(myResponse.alias, encryptedKey);
          //Manage xPub Locally
          sessionStorage.setItem('xPub', xPub);

          return router.push({name: 'Exchange'});

        }else if(res.status == 409){
          isLoading.value = false;
          return router.push({name: 'Auth'});   
        }else if(res.status == 500){
          isLoading.value = false;
          return error.value = "Error de Sistema"  
        }else{
          isLoading.value = false;
          return error.value = "Error"  
        }

      } catch (err) {
        console.log(err);
        isLoading.value = false;
        return error.value = "Error de Conexion"  
      }
    }
    
    const mnemonicCheck = () => {
      
      error.value = null;
      
      let mnemonicString = "";
      const mnemonicFields = Object.values(mnemonics);

      if(mnemonicFields.indexOf('') >= 0){
        return error.value = "Es Necesario Insertar Cada Una de las 12 Pasabras"
      }
      
      //Form Seed String, cleaning it up if necessary
      for(let i = 0; i < mnemonicFields.length; i++){
        let mnemonicWord = mnemonicFields[i];
        if(mnemonicWord === ""){
          mnemonicString +=  "xxx ";
        }else{
          mnemonicWord = mnemonicWord.toLowerCase();
          mnemonicWord = mnemonicWord.replace(/ /gi, "");
          mnemonicString += mnemonicWord + " ";
        }
      }

      mnemonicString = mnemonicString.substr(0, mnemonicString.length - 1);

      //Test mnemoninc string against bip39 dictionary
      const bip39Test = window.testChecksum(mnemonicString);

      //If is not bip39, show error 
      if(bip39Test == false){
        return error.value = "Esta No Es Una Semilla BIP39 Valida. Revise la Ortografia";
      }
      
      //... else go ahead
      const keys = window.walletRecover(mnemonicString);

      return manageEncryptedKeys(keys.xPriv, keys.xPub);

    } 
    
    return { isLoading, error, mnemonics, mnemonicCheck } 
  }
}
</script>