<template>
      <div class = "container">

        <div class="row marginTop lighten-5">

          <div class="col s12 center-align orange">
            <h5 class = "white-text" id = "recoveryTitle">Recuperar Claves</h5>
          </div>
          <div class="col s12 left-align grey darken-1 white-text">
            <p>
              <span id = "recoveryExpl" >
                Inserte abajo las 12 palabras del Código Mnemónico (Semilla).<br>
                Si no recuerda las 12 palabras, haga <b class="orange-text clickable" @click="isActive=true">CLICK AQUI</b>
              </span>
            </p>
          </div>

          <SeedRecoveryForm />

        </div>

    </div>

    <teleport to="body">
      <EmptyModal :isActive="isActive" @close="isActive=false">
        <div class = 'col s12 center-align'>
          <h2 class = 'red white-text errorIcon'><i class = 'fas fa-exclamation-circle'></i></h2>
        </div>
        <div class="col s12 left-align">
          <h6>No Genere una <b>NUEVA SEMILLA</b> si: </h6>
          <p>
            <ul class="browser-default">
              <li>Usted tiene una o mas compraventas abiertas.</li>
              <li>Usted tiene uno o mas anuncios todavia visibles.</li>
            </ul>
          </p>
          <p class="red-text">
            <b>En todos estos casos el cambio de semilla le haria imposible firmar las transacciones</b>
          </p><br>
        </div>
        
        <p>
          <LargeButton color="red" icon="fa fa-check" text="Entiendo" position="left" @clicked="router.push({name: 'Welcome'})"/>
        </p> 
      </EmptyModal>
    </teleport>

</template>

<script>
import useBoilerplate from '@/composables/useBoilerplate'
import SeedRecoveryForm from '@/components/seed/SeedRecoveryForm';
import EmptyModal from '@/components/modals/EmptyModal'
import LargeButton from '@/components/UI/Buttons/LargeButton';

export default {  
  components: { SeedRecoveryForm, EmptyModal, LargeButton },
  setup(){
    const {isActive, router} = useBoilerplate(false);
    return { isActive, router }
  }
}
</script>

<style scoped>
  .clickable{
    cursor: pointer;
  }
</style>